export default function (value) {
  if (!value) {
    return '00 min 00 sec';
  }
  try {
    const [hour = 0, minutes = 0, seconds = 0] = value.split(':');
    if (hour > 0) {
      return `${hour} hour ${minutes} min ${seconds} sec`;
    } else {
      return `${minutes} min ${seconds} sec`;
    }
  } catch (e) {
    return e;
  }
};
