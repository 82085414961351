import {
  PROJECTS_BLOCKED,
  PROJECTS_CREATE,
  PROJECTS_UPDATE,
  PROJECTS_CREATE_TASKS_LIST,
  PROJECTS_GET,
  PROJECTS_GET_ALL
} from '../types/actions.types';
import helper, { offsetUTCinHours } from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import Project from '../../api/methods/Project';

const state = {
  pagination: {}
};

const getters = {
};

const actions = {
  async [PROJECTS_GET_ALL] (vuex, { context, params } = {}) {
    try {
      const data = await Project.getAll(helper.clearObject({ ...params, timezone: offsetUTCinHours() }));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [PROJECTS_GET] (vuex, { projectId } = {}) {
    try {
      const { payload } = await Project.get(projectId);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [PROJECTS_CREATE] (vuex, { context, params = {} }) {
    try {
      const { payload } = await Project.create(params);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [PROJECTS_UPDATE] (vuex, { context, params = {} }) {
    try {
      const { payload } = await Project.uptate(params.id, params.formData);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [PROJECTS_CREATE_TASKS_LIST] (vuex, { projectId, params = {} } = {}) {
    try {
      const { payload } = await Project.addTasksList(projectId, params);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [PROJECTS_BLOCKED] (vuex, { context, params } = {}) {
    try {
      const { payload } = await Project.blocked(params?.projectId);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
