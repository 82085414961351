import { apiClient } from '../config';

export default {
  get (params) {
    return apiClient.get('sms-templates', { params });
  },
  getOne (id, params) {
    return apiClient.get(`sms-templates/${id}`, { params });
  },
  update (id, params) {
    return apiClient.put(`sms-templates/${id}`, params);
  },
  create (params) {
    return apiClient.post('sms-templates', params);
  },
  send (taskId, params) {
    return apiClient.post(`/tasks/${taskId}/sms`, params);
  },
  log (params) {
    return apiClient.get('/sms', { params });
  },
  resend (smsId) {
    return apiClient.post(`/sms/${smsId}/resend`);
  }
};
