import {
  USER_INFO,
  USERS_BLOCKED,
  USERS_CREATE,
  USERS_GET,
  USERS_GET_ALL, USERS_REMOVE_TOKEN_FCM,
  USERS_SET_TOKEN_FCM,
  USERS_UPDATE
} from '../types/actions.types';
import Users from '../../api/methods/Users';
import Admin from '../../api/methods/Admin';
import helper from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import Fcm from '../../api/methods/Fcm';

const state = {
  usersList: [],
  pagination: {}
};

const getters = {
};

const actions = {
  async [USERS_GET_ALL] (vuex, { context, params } = {}) {
    try {
      const data = await Users.getAll(helper.clearObject(params));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [USERS_GET] (vuex, { context, params = {} } = {}) {
    try {
      const { payload } = await Users.get(params.id);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [USERS_UPDATE] (vuex, { context, userId, params = {} } = {}) {
    try {
      const { payload } = await Admin.updateUser(userId, helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [USER_INFO] (vuex, { context } = {}) {
    try {
      const { payload } = await Users.getInfo();
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [USERS_CREATE] (vuex, { context, params = {} } = {}) {
    try {
      const { payload, success } = await Admin.createUser(helper.clearObject(params));

      if (success) {
        context.$Notify({
          title: 'User',
          message: 'Successfully created',
          type: 'success'
        });
      }

      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [USERS_BLOCKED] (vuex, { context, params } = {}) {
    try {
      const { payload } = await Admin.userBlocked(params?.userId);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [USERS_SET_TOKEN_FCM] (vuex, { context, params } = {}) {
    try {
      const { payload } = await Fcm.setToken(params);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [USERS_REMOVE_TOKEN_FCM] (vuex, { params } = {}) {
    try {
      const { payload } = await Fcm.removeToken();
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
