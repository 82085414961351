import { apiClient } from '../config';

export default {
  createUser (params) {
    return apiClient.post('admin/user', params);
  },
  createTeam (params) {
    return apiClient.post('admin/team', params);
  },
  userBlocked (userId) {
    return apiClient.put(`admin/user/${userId}/blocking`);
  },
  removeOperatorToOffice (teamId, leadId) {
    return apiClient.put(`admin/team/${teamId}/lead/${leadId}`);
  },
  updateUser (userId, params) {
    return apiClient.put(`admin/user/${userId}`, params);
  },
  updateTeam (teamId, params) {
    return apiClient.put(`admin/team/${teamId}`, params);
  }
};
