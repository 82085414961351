import { GET_SMS_SCHEMAS } from '../types/actions.types';
import helper from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import Misc from '../../api/methods/Misc';

const state = {
  smsSchemas: []
};

const getters = {
  smsSchemas () {
    return state.smsSchemas;
  }
};

const actions = {
  async [GET_SMS_SCHEMAS] (vuex, { context, params } = {}) {
    if (!helper.isEmpty(vuex.state.smsSchemas)) {
      return Promise.resolve(vuex.state.smsSchemas);
    }
    try {
      const { payload } = await Misc.getSmsSchemas();
      vuex.commit(SET_ALL, { data: payload, key: 'smsSchemas' });
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
};
