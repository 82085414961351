<template>
  <div class="mobile-header">
    <div class="mobile-header__back">
      <call-button
        v-if="computedBackButton"
        :icon="dataIconBack"
        outlet
        type="success"
        @click="clickGoBack()" />
    </div>
    <h1
      :key="rerander"
      class="mobile-header__title">
      {{ computedNameTitle() }}
    </h1>
    <call-input
      v-if="computedSearchShow"
      v-model="computedSearch"
      type-input="search"
      placeholder="Search">
      <template slot="prefix">
        <font-awesome-icon
          :icon="dataIconSearch" />
      </template>
    </call-input>
    <div class="mobile-header__next">
      <call-button
        v-if="computedStoreNextButton"
        :icon="dataIconNext"
        outlet
        type="success"
        @click="clickGoNext()" />
      <call-button
        v-else-if="computedIsFilter"
        :icon="dataIconFilter"
        outlet
        type="success"
        @click="() => $root.$emit('filter-active')">
        <call-badge
          v-if="computedFilterCount > 0"
          class="call-filter-badge"
          type="danger">
          {{ computedFilterCount }}
        </call-badge>
      </call-button>
    </div>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faChevronLeft, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import CallInput from '../common/CallInput';
import { mapGetters } from 'vuex';
import eventBus from '../../service/eventBus';
import { SET_ALL } from '../../store/types/mutations.types';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import CallBadge from '@/components/common/CallBadge';

export default {
  name: 'TheHeaderMobile',
  components: { CallBadge, CallInput, CallButton },
  props: {

  },
  data () {
    return {
      dataIconBack: faChevronLeft,
      dataIconNext: faArrowRight,
      dataIconSearch: faSearch,
      dataIconFilter: faFilter,
      rerander: ''
    };
  },
  computed: {
    ...mapGetters({
      computedStoreSearch: 'mobile/search',
      computedStoreNextButton: 'mobile/nextButton',
      computedStoreFilterPhone: 'mobile/filterPhone',
      computedStoreFilterTime: 'mobile/filterTime'
    }),
    computedSearch: {
      get () {
        return this.computedStoreSearch;
      },
      set (data) {
        this.$store.commit(`mobile/${SET_ALL}`, {
          data,
          key: 'search'
        });
      }
    },
    computedFilterCount () {
      const enableFilterTime = this.computedStoreFilterTime ? 1 : 0;
      return this.computedStoreFilterPhone.filter(item => item.active).length + enableFilterTime;
    },
    computedBackButton () {
      return this.$route.meta.backButton;
    },
    computedIsBackHistoryReplace () {
      return this.$route.query.backHistoryReplace;
    },
    computedSearchShow () {
      return this.$route.meta.search;
    },
    computedIsFilter () {
      return this.$route.name === 'ProjectTasks';
    }
  },

  created () {
    eventBus.$on('reranderHeader', () => {
      this.rerander = Math.random();
    });
  },
  beforeDestroy () {
    eventBus.$off('reranderHeader');
  },

  methods: {
    computedNameTitle () {
      if (this.$route.meta.name[0] === ':') {
        const n = this.$route.meta.name.substring(1);
        return this.$route.query[n] ||
          (n === 'name' ? (this.$route.meta.taskName) : (this.$route.meta[n])) ||
          '';
      }
      return this.$route.meta.name;
    },
    clickGoBack () {
      if (this.$router?.currentRoute?.name === 'ProjectTasks') {
        this.$router.replace({
          name: 'Projects'
        });
      } else if (this.computedIsBackHistoryReplace && this.$router?.currentRoute?.name === 'ProjectTasksDetail') {
        this.$router.replace({
          name: 'ProjectTasks',
          params: {
            projectId: this.$route.params.projectId
          },
          query: {
            projectName: this.$route.query.projectName,
            reset: true
          }
        });
      } else {
        this.$router.back();
      }
      this.computedSearch = null;
      this.$store.commit(`mobile/${SET_ALL}`, { data: false, key: 'nextButton' });
    },
    clickGoNext () {
      this.$root.$emit('go-next');

      this.$store.commit(`mobile/${SET_ALL}`, { data: false, key: 'nextButton' });
    }
  }
};
</script>
