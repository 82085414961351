import { apiClient } from '../config';

export default {
  getList (params) {
    return apiClient.get('/bonuses', { params });
  },
  sendBonus (taskId, params) {
    return apiClient.post(`/task/${taskId}/bonus`, params);
  },
  getBonusesProject (projectId) {
    return apiClient.get(`/bonuses/projects/${projectId}`);
  },
  setBonusesProject (projectId, params) {
    return apiClient.post(`/bonuses/projects/${projectId}`, params);
  }
};
