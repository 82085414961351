import { apiClient } from '../config';

export default {
  getTeam (id, params) {
    return apiClient.get(`/stats/teams/${id}`, { params });
  },
  getAll (params) {
    return apiClient.get('/stats/teams', { params });
  },
  getStatsOperator (teamId, operatorId, params) {
    return apiClient.get(`/stats/teams/${teamId}/operators/${operatorId}`, { params });
  }
};
