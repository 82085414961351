<template>
  <div class="mobile-menu">
    <nav class="nav--mobile">
      <router-link
        :to="{name: 'Projects'}"
        class="nav__item nav__item--mobile">
        <font-awesome-icon :icon="dataIconBriefcase" />
        Projects
      </router-link>
      <router-link
        :to="{name: 'Reports'}"
        class="nav__item nav__item--mobile">
        <font-awesome-icon :icon="dataIconChartBar" />
        Reports
      </router-link>
      <router-link
        :to="{name: 'Settings'}"
        class="nav__item nav__item--mobile">
        <font-awesome-icon :icon="dataIconSettings" />
        Settings
      </router-link>
    </nav>
  </div>
</template>

<script>
import { faBriefcase, faChartBar, faCog } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallMenuTabsMobile',
  data () {
    return {
      dataIconBriefcase: faBriefcase,
      dataIconChartBar: faChartBar,
      dataIconSettings: faCog
    };
  }
};
</script>
