import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth.module';
import user from './modules/user.module';
import team from './modules/team.module';
import project from './modules/project.module';
import task from './modules/task.module';
import report from './modules/report.module';
import mobile from './modules/mobile.module';
import sms from './modules/sms.module';
import call from './modules/call.module';
import bonus from './modules/bonus.module';
import issues from './modules/issues.module';
import misc from './modules/misc.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    team,
    project,
    task,
    report,
    mobile,
    sms,
    call,
    bonus,
    issues,
    misc
  }
});
