<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Exit Login"
    @close="onCloseModal">
    <div>
      Are you sure you want to quit?
    </div>
    <template v-slot:modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataExitIcon"
        type="success"
        @click="clickLogout()">
        Exit
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CallButton from '../CallButton';
import { LOGOUT } from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';

export default {
  name: 'CallModalLogout',
  components: {
    CallButton,
    BModal
  },
  mixins: [iconsModal],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataModal: this.value
    };
  },
  watch: {
    async value () {
      this.dataModal = this.value;
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickLogout () {
      try {
        await this.$store.dispatch(`auth/${LOGOUT}`, {
          context: this
        });
        this.onCloseModal();
        window.location.reload();
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
