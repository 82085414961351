<template>
  <button
    :disabled="buttonDisabled || loading"
    :autofocus="autofocus"
    :type="nativeType"
    :class="[
      `call-button call-button--${type} call-button-size--${size}`,
      {
        'is-disabled': buttonDisabled,
        'is-loading': loading,
        'is-plain': plain,
        'is-outlet': outlet,
        'is-round': round,
        'is-full': full,
        'is-not-border': notBorder,
        'is-fourth': fourth
      }
    ]"
    @click="handleClick">
    <font-awesome-icon
      v-if="loading"
      class="call-icon-loading"
      :icon="dataIconLoading" />
    <font-awesome-icon
      v-if="icon && !loading"
      :icon="icon" />
    <slot />
  </button>
</template>
<script>
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallButton',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'medium'
    },
    color: {
      type: String,
      default: 'default'
    },
    icon: {
      type: Object,
      default: () => {}
    },
    nativeType: {
      type: String,
      default: 'button'
    },
    loading: Boolean,
    plain: Boolean,
    outlet: Boolean,
    round: Boolean,
    full: Boolean,
    disabled: Boolean,
    notBorder: Boolean,
    autofocus: Boolean,
    fourth: Boolean
  },
  data () {
    return {
      dataIconLoading: faSpinner
    };
  },
  computed: {
    buttonDisabled () {
      return this.disabled;
    }
  },
  methods: {
    handleClick (evt) {
      this.$emit('click', evt);
    }
  }
};
</script>
