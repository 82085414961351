import { SET_ALL } from '../types/mutations.types';
import storage from '../../service/storage';
import { MOBILE_CALL_BLOCKED } from '../types/actions.types';

const state = {
  search: null,
  nextButton: false,
  filterPhone: [
    {
      active: false,
      name: 'Successful',
      key: 'success'
    },
    {
      active: false,
      name: 'Cancelled',
      key: 'cancelled'
    },
    {
      active: false,
      name: 'No Answer',
      key: 'noanswer'
    },
    {
      active: false,
      name: 'Rejected',
      key: 'rejected'
    }
  ],
  blockedCall: {},
  filterTime: null
};

const getters = {
  search (state) {
    return state.search;
  },
  nextButton (state) {
    return state.nextButton;
  },
  filterPhone (state) {
    return state.filterPhone;
  },
  filterTime (state) {
    return state.filterTime;
  },
  blockedCall (state) {
    return state.blockedCall;
  }
};

const actions = {
  [MOBILE_CALL_BLOCKED] (vuex) {
    const callBlockedParams = storage.get('blocked_call');
    vuex.commit(SET_ALL, { data: callBlockedParams, key: 'blockedCall' });
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
