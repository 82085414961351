import Vue from 'vue';
import VueRouter from 'vue-router';
// import NProgress from 'nprogress';
import { routesDesktop } from './desktop';
import { routesMobile } from './mobile';
import device from 'vue-device-detector';

Vue.use(VueRouter);
Vue.use(device);

const router = new VueRouter({
  linkActiveClass: 'is-active',
  mode: 'history',
  base: '/',
  routes: Vue.$device.mobile ? routesMobile : routesDesktop
});

router.afterEach(() => {
  setTimeout(() => {
    // NProgress.done();
  });
});

export default router;
