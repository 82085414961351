import { apiClient } from '../config';

export default {
  get (id) {
    return apiClient.get(`users/${id}`);
  },
  getAll (params) {
    return apiClient.get('users', { params });
  },
  getInfo () {
    return apiClient.get('users/me');
  }
};
