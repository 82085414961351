import { apiClient } from '../config';

export default {
  get (id) {
    return apiClient.get(`/tasks/${id}`);
  },
  getAll (params) {
    return apiClient.get('/tasks', { params });
  },
  update (id, params) {
    return apiClient.put(`tasks/${id}`, params);
  },
  close (id, params) {
    return apiClient.put(`tasks/${id}/close`, params);
  },
  addComment (id, params) {
    return apiClient.post(`tasks/${id}/comment`, params);
  },
  deleteComment (taskId, commentId) {
    return apiClient.delete(`/tasks/${taskId}/comment/${commentId}`);
  },
  updateComment (taskId, commentId, params) {
    return apiClient.put(`/tasks/${taskId}/comment/${commentId}`, params);
  }
};
