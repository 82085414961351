import helper, { offsetUTCinHours } from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import {
  ISSUES_CREATE,
  ISSUES_CREATE_CATEGORIES,
  ISSUES_GET,
  ISSUES_GET_CATEGORIES,
  ISSUES_UPDATE, ISSUES_UPDATE_CATEGORIES
} from '@/store/types/actions.types';
import Issues from '@/api/methods/Issues';

const state = {
};

const getters = {
};

const actions = {
  async [ISSUES_CREATE_CATEGORIES] (vuex, { context, params } = {}) {
    try {
      const { payload } = await Issues.createCategories(helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [ISSUES_UPDATE_CATEGORIES] (vuex, { context, id, params } = {}) {
    try {
      const { payload } = await Issues.updateCategories(id, helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [ISSUES_GET_CATEGORIES] (vuex, { context, params = {} } = {}) {
    try {
      const { payload } = await Issues.getCategories(helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [ISSUES_CREATE] (vuex, { context, params = {} } = {}) {
    try {
      const { payload } = await Issues.create(helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [ISSUES_GET] (vuex, { context, params = {} } = {}) {
    try {
      const data = await Issues.get(helper.clearObject({ ...params, timezone: offsetUTCinHours() }));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [ISSUES_UPDATE] (vuex, { context, id, params = {} } = {}) {
    try {
      const { payload } = await Issues.update(id, helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
