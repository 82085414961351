import helper, { offsetUTCinHours } from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import Call from '@/api/methods/Call';
import { CALL_GET_RECORD, CALL_GET_USERS, CALL_UPDATE } from '@/store/types/actions.types';
import { CALL_CREATE, CALL_GET_LOGS } from '../types/actions.types';

const actions = {
  async [CALL_GET_USERS] (vuex, { params } = {}) {
    try {
      const data = await Call.getUsersCall(helper.clearObject(params));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [CALL_GET_RECORD] (vuex, { params = {} } = {}) {
    try {
      const { payload } = await Call.getRecord(params.id);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [CALL_GET_LOGS] (vuex, { params = {} } = {}) {
    try {
      const data = await Call.getLogsCall({ ...params, timezone: offsetUTCinHours() });
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [CALL_UPDATE] (vuex, { params, callId } = {}) {
    try {
      const { payload } = await Call.updateCall(callId, params);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [CALL_CREATE] (vuex, { params, taskId } = {}) {
    try {
      const { payload } = await Call.createCall(taskId, params);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  actions,
  mutations
};
