import { apiClient } from '../config';

export default {
  get (id) {
    return apiClient.get(`/projects/${id}`);
  },
  getAll (params) {
    return apiClient.get('/projects', { params });
  },
  create (params) {
    return apiClient.post('/projects', params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  uptate (id, params) {
    return apiClient.put(`/projects/${id}`, params);
  },
  blocked (projectId) {
    return apiClient.delete(`/projects/${projectId}/activity`);
  },
  addTasksList (projectId, params) {
    return apiClient.post(`/projects/${projectId}/tasks-file`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};
