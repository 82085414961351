import axios from 'axios';
import store from '../store';
import Notification from '../components/common/notification';
import { Notify } from 'vant';
import Vue from 'vue';
import { PURGE_AUTH } from '../store/types/mutations.types';
import NProgress from 'nprogress';

const loadingStack = [];
export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_THEME === 'betwinner' ? 'https://api.bw-cc.com/' : 'https://call-center-api.staging.svc-cloud.com/',
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 30000
});

apiClient.interceptors.request.use(config => {
  loadingStack.push(true);
  NProgress.start();
  config.headers.Authorization = store.getters['auth/token'];
  return config;
}, error => {
  Promise.reject(error);
});

apiClient.interceptors.response.use(
  response => {
    loadingStack.pop();
    if (!loadingStack.length) {
      NProgress.done();
    }
    return response.data;
  }, (error = {}) => {
    loadingStack.pop();
    if (!loadingStack.length) {
      NProgress.done();
    }

    if (error.code === 'ECONNABORTED') {
      if (Vue.$device.mobile) {
        Notify({ type: 'danger', message: `${error?.name}: ${error?.message}` });
      } else {
        Notification({
          title: `Status code ${error.code}`,
          message: `${error?.name}: ${error?.message}`,
          type: 'error'
        });
      }
    } else {
      const { response: { data: { errors = [] }, status = null } = {} } = error;

      switch (status) {
        case 401:
          store.commit(`auth/${PURGE_AUTH}`);
          Vue.$cookies.remove('authInfo');
          if (!~location.href.indexOf('login')) {
            location.reload();
          } else {
            errors.forEach(({ message: messageText = '', code = '' }) => {
              if (Vue.$device.mobile) {
                Notify({ type: 'danger', message: `Status code: ${status}, ${messageText}` });
              } else {
                Notification({
                  title: `Status code ${status}`,
                  message: messageText,
                  type: 'error'
                });
              }
            });
          }
          break;
        default:
          if (errors.length === 0) {
            if (Vue.$device.mobile) {
              Notify({ type: 'danger', message: `${error?.name}: ${error?.message}` });
            } else {
              Notification({
                title: `Status code ${error.code || error?.response?.status}`,
                message: `${error?.name}: ${error?.message}`,
                type: 'error'
              });
            }
          } else {
            errors.forEach(({ message: messageText = '', code = '' }) => {
              if (Vue.$device.mobile) {
                Notify({ type: 'danger', message: `Status code: ${status}, ${messageText}` });
              } else {
                Notification({
                  title: `Status code ${status}`,
                  message: messageText,
                  type: 'error'
                });
              }
            });
          }
          break;
      }
    }

    return Promise.reject(error);
  });
