import { apiClient } from '../config';

export default {
  setToken (params) {
    return apiClient.post('/users/me/firebase-token', params);
  },
  removeToken (params) {
    return apiClient.delete('/users/me/firebase-token', { data: params });
  }
};
