import PublicLayout from '../layouts/Public';
import AppLayout from '../layouts/App';
import { ID_ROLE_OPERATOR, ID_ROLE_TEAM_LEADER } from '../service/consts';

export const routesDesktop = [
  {
    path: '/login',
    component: PublicLayout,
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('../views/Login')
      }
    ]
  },
  {
    path: '/users',
    component: AppLayout,
    meta: {
      requiresAuth: true,
      role: [
        {
          type: ID_ROLE_TEAM_LEADER,
          allow: true
        },
        {
          type: ID_ROLE_OPERATOR,
          allow: false
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'Users',
        component: () => import('../views/desktop/users/Users'),
        meta: {
          breadCrumbs: [{ name: 'Users', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      },
      {
        path: '/users/:id',
        name: 'UserDetail',
        component: () => import('../views/desktop/users/UsersDetail'),
        meta: {
          breadCrumbs: [{ name: 'Users', routeName: 'Users' }, { name: ':userName', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      }
    ]
  },
  {
    path: '/teams',
    component: AppLayout,
    meta: {
      requiresAuth: true,
      role: [
        {
          type: ID_ROLE_TEAM_LEADER,
          allow: true
        },
        {
          type: ID_ROLE_OPERATOR,
          allow: false
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'Teams',
        component: () => import('../views/desktop/teams/Teams'),
        meta: {
          breadCrumbs: [{ name: 'Teams', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      },
      {
        path: '/teams/:id',
        name: 'TeamDetail',
        component: () => import('../views/desktop/teams/TeamsDetail'),
        meta: {
          breadCrumbs: [{ name: 'Teams', routeName: 'Teams' }, { name: ':teamName', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      }
    ]
  },
  {
    path: '/projects',
    component: AppLayout,
    meta: {
      requiresAuth: true,
      role: [
        {
          type: ID_ROLE_TEAM_LEADER,
          allow: true
        },
        {
          type: ID_ROLE_OPERATOR,
          allow: true
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'Projects',
        component: () => import('../views/desktop/projects/Projects'),
        meta: {
          breadCrumbs: [{ name: 'Projects', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: true
            }
          ]
        }
      },
      {
        path: '/projects/:projectId/tasks',
        name: 'ProjectTasks',
        component: () => import('../views/desktop/tasks/Tasks'),
        meta: {
          breadCrumbs: [{ name: 'Projects', routeName: 'Projects' }, { name: ':projectName', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: true
            }
          ]
        }
      },
      {
        path: '/projects/:projectId/tasks/:taskId',
        name: 'ProjectTasksDetail',
        component: () => import('../views/desktop/tasks/TasksDetail'),
        meta: {
          breadCrumbs: [{ name: 'Projects', routeName: 'Projects' }, { name: ':projectName', routeName: 'ProjectTasks' }, { name: ':taskName', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: true
            }
          ]
        }
      }
    ]
  },
  {
    path: '/settings',
    component: AppLayout,
    meta: {
      requiresAuth: true,
      role: [
        {
          type: ID_ROLE_TEAM_LEADER,
          allow: true
        },
        {
          type: ID_ROLE_OPERATOR,
          allow: true
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'Settings',
        component: () => import('../views/desktop/Settings'),
        meta: {
          breadCrumbs: [{ name: 'Settings', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: true
            }
          ]
        }
      }
    ]
  },
  {
    path: '/calls',
    redirect: '/calls/records',
    component: AppLayout,
    meta: {
      requiresAuth: true,
      role: [
        {
          type: ID_ROLE_TEAM_LEADER,
          allow: true
        },
        {
          type: ID_ROLE_OPERATOR,
          allow: false
        }
      ]
    },
    children: [
      {
        path: '/calls/records',
        name: 'CallsRecords',
        component: () => import('../views/desktop/calls/Calls'),
        meta: {
          breadCrumbs: [{ name: 'Calls', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      },
      {
        path: '/calls/logs',
        name: 'CallsLogs',
        component: () => import('../views/desktop/calls/CallsLogs'),
        meta: {
          breadCrumbs: [{ name: 'Calls', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      },
      {
        path: '/calls/issues',
        name: 'CallsIssues',
        component: () => import('../views/desktop/calls/CallsIssues'),
        meta: {
          breadCrumbs: [{ name: 'Calls', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: false
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      },
      {
        path: '/calls/report',
        name: 'CallsReport',
        component: () => import('../views/desktop/calls/CallsReport'),
        meta: {
          breadCrumbs: [{ name: 'Calls', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      }
    ]
  },
  {
    path: '/reports',
    component: AppLayout,
    meta: {
      requiresAuth: true,
      role: [
        {
          type: ID_ROLE_TEAM_LEADER,
          allow: true
        },
        {
          type: ID_ROLE_OPERATOR,
          allow: true
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'Reports',
        component: () => import('../views/desktop/reports/Reports'),
        meta: {
          breadCrumbs: [{ name: 'Reports', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      },
      {
        path: '/operator',
        name: 'ReportsDetailOperator',
        component: () => import('../views/desktop/reports/ReportsDetailOperator'),
        meta: {
          breadCrumbs: [{ name: 'Reports', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: false
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: true
            }
          ]
        }
      },
      {
        path: '/reports/:reportId',
        name: 'ReportDetail',
        component: () => import('../views/desktop/reports/ReportsDetail'),
        meta: {
          breadCrumbs: [{ name: 'Reports', routeName: 'Reports' }, { name: ':reportName', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      }
    ]
  },
  {
    path: '/sms',
    name: 'sms',
    redirect: '/sms/template',
    component: AppLayout,
    meta: {
      requiresAuth: true,
      role: [
        {
          type: ID_ROLE_TEAM_LEADER,
          allow: true
        },
        {
          type: ID_ROLE_OPERATOR,
          allow: false
        }
      ]
    },
    children: [
      {
        path: '/sms/template',
        name: 'SmsTemplate',
        component: () => import('../views/desktop/sms/SmsTemplate'),
        meta: {
          breadCrumbs: [{ name: 'SMS Template', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: false
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      },
      {
        path: '/sms/logs',
        name: 'SmsLogs',
        component: () => import('../views/desktop/sms/SmsLogs'),
        meta: {
          breadCrumbs: [{ name: 'SMS Logs', routeName: null }],
          role: [
            {
              type: ID_ROLE_TEAM_LEADER,
              allow: true
            },
            {
              type: ID_ROLE_OPERATOR,
              allow: false
            }
          ]
        }
      }
    ]
  },
  { path: '/', redirect: '/users' },
  { path: '*', redirect: '/users' },
  {
    path: '/theme',
    name: 'Theme',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  }
];
