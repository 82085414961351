<template>
  <div>
    <the-header-mobile />
    <div class="mobile-body">
      <keep-alive>
        <router-view :key="$route.name" />
      </keep-alive>
    </div>
    <call-menu-tabs-mobile />
    <call-modal-logout v-model="dataModalLogout" />
  </div>
</template>

<script>
import TheHeaderMobile from '../components/mobile/TheHeaderMobile';
import CallMenuTabsMobile from '../components/mobile/CallMenuTabsMobile';
import CallModalLogout from '../components/common/modals/CallLogout';
import helper from '../service/helper';
import { MOBILE_CALL_BLOCKED } from '../store/types/actions.types';
import { mapGetters } from 'vuex';

export default {
  name: 'AppLayout',
  components: { CallModalLogout, CallMenuTabsMobile, TheHeaderMobile },
  data () {
    return {
      dataModalLogout: false
    };
  },
  computed: {
    ...mapGetters({
      storeBlockedCall: 'mobile/blockedCall'
    })
  },
  watch: {
    storeBlockedCall: {
      immediate: true,

      // We have to move our method to a handler field
      handler () {
        if (!helper.isEmpty(this.storeBlockedCall)) {
          this.$router.replace({
            path: this.storeBlockedCall.path
          });
        }
      }
    }
  },
  beforeCreate () {
    navigator.serviceWorker.addEventListener('message', event => {
      if (event.data.action === 'notification') {
        if (this.$router?.currentRoute?.name === 'ProjectTasksDetail') {
          window.location.replace(event.data.path);
        } else {
          this.$router.replace({
            path: event.data.path
          });
        }
      }
    });
  },
  async mounted () {
    this.$root.$on('logout', () => {
      this.dataModalLogout = true;
    });
    this.$store.dispatch(`mobile/${MOBILE_CALL_BLOCKED}`);
  }
};
</script>
