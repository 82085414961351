import dayjs from 'dayjs';
import helper from '../../service/helper';
const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
dayjs.extend(timezone);

export default function (value, format = 'DD MMM YYYY HH:mm', timeZone = (-(new Date().getTimezoneOffset() / 60)).toFixed(0)) {
  if (!value) {
    return null;
  } else if (helper.typeOf(value) === 'number' && String(value).length < 13) {
    value = new Date(1000 * value);
  } else {
    value = new Date(value);
  }

  return dayjs(new Date(value.getTime() + (3600000 * timeZone))).format(format);
};
