import { apiClient } from '../config';

export default {
  call (taskId) {
    return apiClient.post(`/tasks/${taskId}/call`);
  },
  getUsersCall (params) {
    return apiClient.get('calls/operator-grouped', { params });
  },
  getRecord (userId) {
    return apiClient.get(`calls/${userId}/record-file`);
  },
  updateCall (callId, params) {
    return apiClient.put(`calls/${callId}`, params);
  },
  createCall (taskId, params) {
    return apiClient.post(`/tasks/${taskId}/call/manual`, params);
  },
  getLogsCall (params) {
    return apiClient.get('calls', { params });
  }
};
