import helper from '../../service/helper';

export default function (value) {
  if (helper.typeOf(value) !== 'array') {
    return '';
  }
  try {
    return value.map(item => item.name || '').join(',');
  } catch (e) {
    return e;
  }
};
