<template>
  <div
    :class="[
      'call-input',
      'call-input__' + typeInput,
      {
        'is-disabled': inputDisabled,
        'call-input--prefix': $slots.prefix || prefixIcon,
        'call-input--error': error,
        'like': floatNameLabel,
        'has-value': true,
        'is-valide': valide
      }
    ]">
    <span
      v-if="$slots.prefix || prefixIcon"
      class="call-input__prefix"
      :class="classNamePrefix">
      <slot name="prefix" />
    </span>
    <input
      ref="input"
      :name="name"
      :tabindex="tabindex"
      class="call-input__inner"
      :class="classNameInput"
      :style="styleInput"
      v-bind="$attrs"
      :type="showPassword ? (passwordVisible ? 'text': 'password') : type"
      :disabled="inputDisabled"
      :readonly="readonly"
      :autocomplete="autocomplete"
      :aria-label="label"
      required
      @input="handleInput"
      @change="handleChange">
    <font-awesome-icon
      v-if="showPassword"
      class="call-input__icon call-icon-view call-input__clear"
      :icon="passwordVisible ? dataShowPasswordHideIcon : dataShowPasswordIcon"
      @click="handlePasswordVisible" />
    <label v-if="floatNameLabel">{{ floatNameLabel }}</label>
  </div>
</template>
<script>
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
export default {
  name: 'CallInput',
  props: {
    name: {
      type: String,
      default: ''
    },
    value: [String, Number],
    classNamePrefix: {
      type: String,
      default: ''
    },
    classNameInput: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    readonly: Boolean,
    error: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    typeInput: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    prefixIcon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    floatNameLabel: {
      type: String,
      default: null
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: ''
    },
    valide: {
      type: Boolean,
      default: false
    },
    styleInput: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      passwordVisible: false,
      dataShowPasswordIcon: faEye,
      dataShowPasswordHideIcon: faEyeSlash
    };
  },
  computed: {
    inputDisabled () {
      return this.disabled;
    }
  },
  watch: {
    value () {
      this.getInput().value = this.value;
    },
    showPassword () {
      this.handlePasswordVisible();
    }
  },
  mounted () {
    this.getInput().value = this.value;
  },
  methods: {
    getInput () {
      return this.$refs.input;
    },
    focus () {
      this.getInput().focus();
    },
    handlePasswordVisible () {
      this.passwordVisible = !this.passwordVisible;
      this.focus();
    },
    handleInput (event) {
      this.$emit('input', event.target.value, event);
    },
    handleChange (event) {
      this.$emit('change', event.target.value);
    }
  }
};
</script>
