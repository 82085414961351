export default function (value) {
  if (!value) {
    return '';
  }
  try {
    const [hour = 0, min = 0, sec = 0] = value.split(':');
    return `${+hour > 9 ? hour : '0' + +hour}h ${+min > 9 ? min : '0' + +min}m ${+sec > 9 ? sec : '0' + +sec}s`;
  } catch (e) {
    return e;
  }
};
