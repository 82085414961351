import { apiClient } from '../config';

export default {
  createCategories (params) {
    return apiClient.post('issue-categories', params);
  },
  updateCategories (id, params) {
    return apiClient.put(`issue-categories/${id}`, params);
  },
  getCategories (params) {
    return apiClient.get('issue-categories', { params });
  },
  get (params) {
    return apiClient.get('issues', { params });
  },
  create (params) {
    return apiClient.post('issues', params);
  },
  update (id, params) {
    return apiClient.put(`issues/${id}`, params);
  }
};
