import Vue from 'vue';
import date from './methods/date';
import listName from './methods/listName';
import dateBrithday from './methods/dateBrithday';
import dateCall from './methods/dateCall';
import dateReport from './methods/dateReport';
import get from './methods/get';

Vue.filter('dateFilter', date);
Vue.filter('joinName', listName);
Vue.filter('dateBrithday', dateBrithday);
Vue.filter('dateCall', dateCall);
Vue.filter('dateReport', dateReport);
Vue.filter('get', get);
