import {
  TEAM_ACTIVE_USER_OR_DEACTIVATE,
  TEAMS_BLOCKED,
  TEAMS_CREATE,
  TEAMS_GET,
  TEAMS_GET_ALL,
  TEAMS_UPDATE
} from '../types/actions.types';
import Admin from '../../api/methods/Admin';
import helper from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import Teams from '../../api/methods/Teams';

const state = {
  teamsList: [],
  pagination: {}
};

const getters = {
  teamsList (state) {
    return state.teamsList.map((item) => {
      item.actions = false;
      return item;
    });
  }
};

const actions = {
  async [TEAMS_GET_ALL] (vuex, { context, params } = {}) {
    try {
      const data = await Teams.getAll(helper.clearObject(params));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TEAMS_GET] (vuex, { context, params = {} } = {}) {
    try {
      const { payload } = await Teams.get(params.id);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TEAMS_UPDATE] (vuex, { context, teamId, params = {} } = {}) {
    try {
      const { payload } = await Admin.updateTeam(teamId, helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TEAMS_CREATE] (vuex, { context, params = {} } = {}) {
    try {
      const { payload, success } = await Admin.createTeam(helper.clearObject(params));

      if (success) {
        context.$Notify({
          title: 'Team',
          message: 'Successfully created',
          type: 'success'
        });
      }

      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TEAMS_BLOCKED] (vuex, { context, teamId, is_blocked: isBlocked } = {}) {
    try {
      const { payload } = await Admin.updateTeam(teamId, {
        is_active: isBlocked
      });
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TEAM_ACTIVE_USER_OR_DEACTIVATE] (vuex, { teamId, operatorId } = {}) {
    try {
      const { payload } = await Teams.activeOperatorInTeam(teamId, operatorId);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
