import {
  SMS_LOGS, SMS_RESEND,
  SMS_SEND,
  SMS_TEMPLATE_CREATE,
  SMS_TEMPLATE_GET,
  SMS_TEMPLATE_GET_ALL,
  SMS_TEMPLATE_UPDATE
} from '../types/actions.types';
import helper, { offsetUTCinHours } from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import Sms from '@/api/methods/Sms';

const actions = {
  async [SMS_TEMPLATE_GET_ALL] (vuex, { context, params } = {}) {
    try {
      const data = await Sms.get(helper.clearObject(params));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [SMS_TEMPLATE_GET] (vuex, { context, params, id } = {}) {
    try {
      const { payload } = await Sms.getOne(id, helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [SMS_TEMPLATE_CREATE] (vuex, { context, params = {} } = {}) {
    try {
      const { payload } = await Sms.create(helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [SMS_TEMPLATE_UPDATE] (vuex, { context, templateId, params = {} } = {}) {
    try {
      const { payload } = await Sms.update(templateId, params);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [SMS_SEND] (vuex, { context, taskId, params = {} } = {}) {
    try {
      const { payload } = await Sms.send(taskId, helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [SMS_LOGS] (vuex, { context, params = {} } = {}) {
    try {
      const data = await Sms.log(helper.clearObject({ ...params, timezone: offsetUTCinHours() }));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [SMS_RESEND] (vuex, { context, smsId } = {}) {
    try {
      const data = await Sms.resend(smsId);
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  actions,
  mutations
};
