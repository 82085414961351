<template>
  <div>
    <the-header />
    <div class="main">
      <call-menu />
      <div class="content">
        <div
          v-if="computedBreadCrumbs.length === 1"
          class="name-page">
          {{ computedBreadCrumbs[0].name }}
        </div>
        <div
          v-else
          class="breadcrumb">
          <span
            v-for="(item, index) in computedBreadCrumbs"
            :key="item.name+item.routeName">
            <span v-if="item.routeName">
              <span
                class="breadcrumb__item"
                style="color:#28a745;font-family: cera-regular;cursor: pointer"
                @click="clickGotTo(item)">
                {{ tempRouteName(item.name) }}
              </span>
              <font-awesome-icon
                v-if="computedBreadCrumbs.length - 1 !== index"
                :icon="dataIconRight" />
            </span>
            <span
              v-else
              class="breadcrumb__item">
              {{ tempRouteName(item.name) }}
              <b-badge
                v-if="computedIsVip"
                variant="vip">
                VIP
              </b-badge>
            </span>
          </span>
        </div>
        <router-view />
        <call-modal-logout v-model="dataModalLogout" />
      </div>
    </div>
    <div class="footer" />
  </div>
</template>

<script>
import TheHeader from '../components/TheHeader';
import CallMenu from '../components/desktop/CallMenu';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import CallModalLogout from '../components/common/modals/CallLogout';
import { BBadge } from 'bootstrap-vue';

export default {
  name: 'AppLayout',
  components: {
    CallModalLogout,
    CallMenu,
    TheHeader,
    BBadge
  },
  data () {
    return {
      dataIconRight: faAngleRight,
      dataModalLogout: false
    };
  },
  computed: {
    computedBreadCrumbs () {
      return this.$route.meta.breadCrumbs;
    },
    computedIsVip () {
      const parseVip = typeof this.$route.query?.isVip === 'string'
        ? JSON.parse(this.$route.query?.isVip)
        : this.$route.query?.isVip;
      return parseVip && this.$route.name === 'ProjectTasks';
    }
  },
  async mounted () {
    this.$root.$on('logout', () => {
      this.dataModalLogout = true;
    });
  },
  methods: {
    tempRouteName (name = '') {
      if (name[0] === ':') {
        return this.$route.query[name.substring(1)] || '';
      }
      return name;
    },
    clickGotTo ({ routeName }) {
      this.$router.push({
        name: routeName,
        query: this.$route.query
      });
    }
  }
};
</script>
