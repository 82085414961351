import dayjs from 'dayjs';

export default function (value, format = 'DD MMMM YYYY') {
  if (!value) {
    return '';
  }
  try {
    return dayjs(new Date(value), format);
  } catch (e) {
    return e;
  }
};
