import { apiClient } from '../config';

export default {
  get (id) {
    return apiClient.get(`teams/${id}`);
  },
  getAll (params) {
    return apiClient.get('teams', { params });
  },
  activeOperatorInTeam (teamId, operatorId) {
    return apiClient.put(`teams/${teamId}/operator/${operatorId}/activation`);
  }
};
