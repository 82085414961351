import { PURGE_AUTH, SET_AUTH, SET_AUTH_ME } from '../types/mutations.types';
import { CHECK_AUTH, LOGIN, LOGOUT, USER_ME } from '../types/actions.types';
import Auth from '../../api/methods/Auth';
import Users from '../../api/methods/Users';
import { ID_ROLE_ADMIN, ID_ROLE_OPERATOR, ID_ROLE_TEAM_LEADER } from '../../service/consts';
import helper from '../../service/helper';
import Fcm from '../../api/methods/Fcm';
import storage from '../../service/storage';

const state = {
  user: {},
  userMe: {},
  isAuthenticated: false
};

const getters = {
  currentUser (state) {
    return state.userMe;
  },
  token () {
    return state.user.id;
  },
  isAuthenticated (state) {
    return state.isAuthenticated;
  },
  isAdmin () {
    return state.userMe?.role === 'admin';
  },
  isModerator () {
    return state.userMe?.role === ID_ROLE_TEAM_LEADER;
  },
  isOperator () {
    return state.userMe?.role === ID_ROLE_OPERATOR;
  }
};

const actions = {
  async [LOGIN] (vuex, { context, params }) {
    try {
      const { payload = {}, success = false } = await Auth.login(params);

      if (success) {
        // eslint-disable-next-line camelcase
        if (~[ID_ROLE_TEAM_LEADER, ID_ROLE_ADMIN].indexOf(payload?.user_role) && context.$device.mobile) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ code: 100 });
        }

        // eslint-disable-next-line camelcase
        if (payload?.user_role === ID_ROLE_OPERATOR && !context.$device.mobile) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ code: 100 });
        }

        vuex.commit(SET_AUTH, payload);
        context.$cookies.set('authInfo', payload, (3600 * 24 * 30));
      } else {
        return Promise.reject(payload);
      }

      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [LOGOUT] (vuex, { context }) {
    try {
      const { token } = storage.get('fcm_token_obj') ?? {};
      if (!helper.isEmpty(token)) {
        await Fcm.removeToken({ token });
      }
      await Auth.logout();
      vuex.commit(PURGE_AUTH);
      context.$cookies.remove('authInfo');
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [CHECK_AUTH] (vuex, { context }) {
    if (vuex.state.isAuthenticated) {
      return Promise.resolve(true);
    }
    const data = context.$cookies.get('authInfo');
    if (data) {
      vuex.commit(SET_AUTH, data);
      return Promise.resolve(true);
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(false);
  },
  async [USER_ME] (vuex, { hotReload = false } = {}) {
    try {
      if (!helper.isEmpty(state.userMe) && !hotReload) {
        return Promise.resolve(state.userMe);
      }
      const { payload: { user = {} } } = await Users.getInfo();
      vuex.commit(SET_AUTH_ME, user);
      return Promise.resolve(user);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_AUTH] (state, user) {
    state.isAuthenticated = true;
    state.user = user;
  },
  [PURGE_AUTH] (state) {
    state.isAuthenticated = false;
    state.user = {};
  },
  [SET_AUTH_ME] (state, user) {
    state.userMe = user;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
