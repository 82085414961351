import { REPORTS_GET_ALL, REPORTS_GET_STATS_OPERATOR, REPORTS_GET_TEAMS } from '../types/actions.types';
import helper, { offsetUTCinHours } from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import Report from '../../api/methods/Report';

const state = {
};

const getters = {
};

const actions = {
  async [REPORTS_GET_ALL] (vuex, { context, params } = {}) {
    try {
      const data = await Report.getAll(helper.clearObject({ ...params, timezone: offsetUTCinHours() }));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [REPORTS_GET_TEAMS] (vuex, { context, teamId, params = {} } = {}) {
    try {
      const { payload } = await Report.getTeam(teamId, helper.clearObject({ ...params }));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [REPORTS_GET_STATS_OPERATOR] (vuex, { context, teamId, operatorId, params = {} } = {}) {
    try {
      const data = await Report.getStatsOperator(teamId, operatorId, helper.clearObject({ ...params, timezone: offsetUTCinHours() }));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
