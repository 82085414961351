import { apiClient } from '../config';

export default {
  login (params) {
    return apiClient.post('auth/login', params);
  },
  logout () {
    return apiClient.post('auth/logout');
  }
};
