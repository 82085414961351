import helper, { offsetUTCinHours } from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import {
  TASK_ADD_COMMENT,
  TASK_CLOSE, TASK_DELETE_COMMENT,
  TASK_PHONE_CLIENT, TASK_UPDATE_COMMENT,
  TASKS_GET,
  TASKS_GET_ALL, TASKS_GET_ALL_MOBILE,
  TASKS_UPDATE, TASKS_UPDATE_ITEM_MOBILE
} from '../types/actions.types';
import Tasks from '../../api/methods/Tasks';
import Call from '../../api/methods/Call';

const state = {
  mobileTaskList: [],
  mobilePaging: {},
  mobileFilter: 'scheduled'
};

const getters = {
  mobileTaskList (state) {
    return state.mobileTaskList;
  },
  mobilePaging (state) {
    return state.mobilePaging;
  }
};

const actions = {
  async [TASKS_GET_ALL] (vuex, { context, params } = {}) {
    try {
      const data = await Tasks.getAll(helper.clearObject({ ...params, timezone: offsetUTCinHours() }));
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TASKS_GET_ALL_MOBILE] (vuex, { context, params, reset = false } = {}) {
    try {
      const data = await Tasks.getAll(helper.clearObject(params));

      vuex.commit(SET_ALL, { data: helper.get(data, 'misc.paging', {}), key: 'mobilePaging' });

      if (reset) {
        vuex.commit(SET_ALL, { data: helper.get(data, 'payload.list', []), key: 'mobileTaskList' });
      } else {
        vuex.commit(SET_ALL, { data: [...vuex.state.mobileTaskList, ...helper.get(data, 'payload.list', [])], key: 'mobileTaskList' });
      }
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TASKS_UPDATE_ITEM_MOBILE] (vuex, { context, itemUpdate, isClosed = false } = {}) {
    try {
      if (helper.isEmpty(vuex, 'state.mobileTaskList')) {
        return Promise.resolve();
      }

      const listSearch = helper.cloneDeep(vuex.state.mobileTaskList).reduce((itemA, itemB) => {
        if (itemUpdate.id === itemB.id) {
          switch (vuex.state.mobileFilter) {
            case 'free':
              return itemA;
          }

          if (isClosed) {
            return itemA;
          }

          const _new = {
            ...itemB,
            ...itemUpdate
          };
          itemA.push(_new);
        } else {
          itemA.push(itemB);
        }

        return itemA;
      }, []);

      vuex.commit(SET_ALL, { data: listSearch, key: 'mobileTaskList' });
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TASKS_GET] (vuex, { context, params = {} } = {}) {
    try {
      const { payload } = await Tasks.get(params.id);

      payload.task.events = payload.task.events.reduce((itemA, itemB) => {
        if (itemB.status === 'success' && itemB.call_mode && itemB.issue_id) {
          itemA.push(itemB);
          itemA.push({
            id: itemB.id + '_issues',
            call_id: itemB.id,
            comment: itemB.issue_comment,
            issue_name: itemB.issue_name,
            issue_id: itemB.issue_id,
            type: 'issue_comment',
            user_id: itemB.operator_id,
            operator_id: itemB.operator_id,
            operator_name: itemB.operator_name
          });
        } else {
          itemA.push(itemB);
        }
        return itemA;
      }, []);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TASKS_UPDATE] (vuex, { context, taskId, params = {} } = {}) {
    try {
      const { payload } = await Tasks.update(taskId, params);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TASK_CLOSE] (vuex, { context, taskId, params = {} } = {}) {
    try {
      const { payload } = await Tasks.close(taskId, params);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TASK_ADD_COMMENT] (vuex, { context, taskId, params = {} } = {}) {
    try {
      const { payload } = await Tasks.addComment(taskId, helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TASK_DELETE_COMMENT] (vuex, { context, taskId, commentId } = {}) {
    try {
      const { payload } = await Tasks.deleteComment(taskId, commentId);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TASK_UPDATE_COMMENT] (vuex, { taskId, commentId, params = {} } = {}) {
    try {
      const { payload } = await Tasks.updateComment(taskId, commentId, helper.clearObject(params));
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [TASK_PHONE_CLIENT] (vuex, { taskId } = {}) {
    try {
      const { payload } = await Call.call(taskId);
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
