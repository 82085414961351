var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    ("call-button call-button--" + _vm.type + " call-button-size--" + _vm.size),
    {
      'is-disabled': _vm.buttonDisabled,
      'is-loading': _vm.loading,
      'is-plain': _vm.plain,
      'is-outlet': _vm.outlet,
      'is-round': _vm.round,
      'is-full': _vm.full,
      'is-not-border': _vm.notBorder,
      'is-fourth': _vm.fourth
    }
  ],attrs:{"disabled":_vm.buttonDisabled || _vm.loading,"autofocus":_vm.autofocus,"type":_vm.nativeType},on:{"click":_vm.handleClick}},[(_vm.loading)?_c('font-awesome-icon',{staticClass:"call-icon-loading",attrs:{"icon":_vm.dataIconLoading}}):_vm._e(),(_vm.icon && !_vm.loading)?_c('font-awesome-icon',{attrs:{"icon":_vm.icon}}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }