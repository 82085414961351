<template>
  <span
    :class="[
      `badge badge-${type} badge-size--${size}`,
      {
        'is-plain': plain,
      }
    ]">
    <slot />
  </span>
</template>
<script>
export default {
  name: 'CallBadge',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    plain: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium'
    }
  }
};
</script>
