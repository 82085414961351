import { faCheck, faSignOutAlt, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  data () {
    return {
      dataAddIcon: faCheck,
      dataCloseIcon: faTimes,
      dataExitIcon: faSignOutAlt,
      dataRefreshIcon: faSyncAlt
    };
  }
};
