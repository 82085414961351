import { BONUS_ADD_PLAYER, BONUS_LIST, GET_BONUS_LIST_PROJECT, SET_BONUS_LIST_PROJECT } from '../types/actions.types';
import helper from '../../service/helper';
import { SET_ALL } from '../types/mutations.types';
import Bonus from '../../api/methods/Bonus';

const state = {
  bonusesList: [],
  bonusesListProject: []
};

const getters = {
  bonusesList () {
    return state.bonusesList;
  },
  bonusesListProject () {
    return state.bonusesListProject;
  }
};

const actions = {
  async [BONUS_LIST] (vuex, { context, params } = {}) {
    if (!helper.isEmpty(vuex.state.bonusesList)) {
      return Promise.resolve(vuex.state.bonusesList);
    }
    try {
      const { payload: { list = [] } = {} } = await Bonus.getList(
        helper.clearObject(params)
      );
      vuex.commit(SET_ALL, { data: list, key: 'bonusesList' });
      return Promise.resolve(list);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [GET_BONUS_LIST_PROJECT] (vuex, { context, projectId } = {}) {
    try {
      const { payload: { list = [] } = {} } = await Bonus.getBonusesProject(
        helper.clearObject(projectId)
      );
      vuex.commit(SET_ALL, { data: list, key: 'bonusesListProject' });
      return Promise.resolve(list);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async [SET_BONUS_LIST_PROJECT] (vuex, { context, projectId, params } = {}) {
    try {
      const { payload } = await Bonus.setBonusesProject(
        projectId,
        params
      );
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },

  async [BONUS_ADD_PLAYER] (vuex, { context, params, taskId } = {}) {
    try {
      const { payload } = await Bonus.sendBonus(
        taskId,
        helper.clearObject(params)
      );
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
};

const mutations = {
  [SET_ALL] (state, { data, key }) {
    state[key] = data;
  }
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
};
