import PublicLayout from '../layouts/Public';
import AppMobile from '../layouts/AppMobile';

export const routesMobile = [
  {
    path: '/login',
    component: PublicLayout,
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('../views/Login')
      }
    ]
  },
  {
    path: '/projects',
    component: AppMobile,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Projects',
        component: () => import('../views/mobile/projects/ProjectsMobile'),
        meta: {
          backButton: false,
          name: 'Projects',
          search: true
        }
      },
      {
        path: '/projects/:projectId',
        name: 'ProjectTasks',
        component: () => import('../views/mobile/tasks/TasksMobile'),
        meta: {
          backButton: true,
          name: ':projectName',
          search: true,
          projectName: ''
        }
      },
      {
        path: '/projects/:projectId/:taskId',
        name: 'ProjectTasksDetail',
        component: () => import('../views/mobile/tasks/TasksDetailMobile'),
        meta: {
          backButton: true,
          name: ':name',
          search: false,
          taskName: ''
        }
      }
    ]
  },
  {
    path: '/settings',
    component: AppMobile,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Settings',
        component: () => import('../views/mobile/SettingsMobile'),
        meta: {
          backButton: false,
          name: 'Settings',
          search: false
        }
      }
    ]
  },
  {
    path: '/reports',
    component: AppMobile,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Reports',
        component: () => import('../views/mobile/reports/ReportMobile'),
        meta: {
          backButton: false,
          name: 'Reports',
          search: false
        }
      }
    ]
  },
  { path: '/', redirect: '/projects' },
  { path: '*', redirect: '/projects' }
];
